import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET, DELETE, PATCH } from "../../services/apiServices";
import toast from "react-hot-toast";

// Create an async thunk for fetching saved searches with pagination
export const fetchSavedSearches = createAsyncThunk(
  "savedSearches/fetchSavedSearches",
  async (currentPage, { rejectWithValue }) => {
    try {
      const response = await GET(`/saved-searches?page=${currentPage}`); // Call the API with pagination
      return response; // Ensure this is the array of searches
    } catch (error) {
      return rejectWithValue(error.response.data); // Handle errors
    }
  }
);

// Thunk for deleting a saved search
export const deleteSavedSearch = createAsyncThunk(
  "savedSearches/deleteSavedSearch",
  async (searchId, { rejectWithValue }) => {
    try {
      await DELETE(`saved-searches/${searchId}`); // Adjust the endpoint as necessary
      return searchId; // Return the ID of the deleted search
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const toggleNotification = createAsyncThunk(
  "savedSearches/toggleNotification",
  async (searchId, { rejectWithValue }) => {
    try {
      const response = await PATCH(`saved-searches/${searchId}/notifications`);
      console.log("response", response.data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const savedSearchesSlice = createSlice({
  name: "savedSearches",
  initialState: {
    searches: [], // Ensure this is defined as an array
    pagination: {
      currentPage: 1,
      totalPages: null,
      totalItems: null,
      itemsPerPage: 10,
    },
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSavedSearches.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSavedSearches.fulfilled, (state, action) => {
        state.loading = false;
        // Adjust based on actual API response structure
        state.searches = action.payload.data.savedSearches || action.payload; // Ensure it's an array
        state.pagination = action.payload.data.pagination || state.pagination;
      })
      .addCase(fetchSavedSearches.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Set the error message
      })
      .addCase(deleteSavedSearch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSavedSearch.fulfilled, (state, action) => {
        state.loading = false;
        // Remove the deleted search from the state
        state.searches = state.searches.filter(
          (search) => search.id !== action.payload
        );
        toast.success("Search deleted successfully!");
      })
      .addCase(deleteSavedSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "Delete failed";
        toast.error(state.error);
      })
      .addCase(toggleNotification.pending, (state) => {
        // state.loading = true;
        state.error = null;
      })
      .addCase(toggleNotification.fulfilled, (state, action) => {
        // state.loading = false;
        console.log("action.payload", action.payload);
        const updatedSearches = state.searches.map((search) =>
          search.id === action.payload.id
            ? { ...search, notify_new_leads: action.payload.notify_new_leads }
            : search
        );
        console.log("updatedSearches", updatedSearches);
        state.searches = updatedSearches;
        toast.success("Notification status updated successfully!");
      })
      .addCase(toggleNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "Toggle notification failed";
        toast.error(state.error);
      });
  },
});

// Export the reducer
export default savedSearchesSlice.reducer;
