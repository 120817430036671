import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET, POST } from "../../services/apiServices"; // Adjust the path as needed

// Create an async thunk for updating the filters
export const updateFilters = createAsyncThunk(
  "search/updateFilters",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await POST(
        `search?page=${formData?.currentPage || 1}`,
        formData
      ); // Call the API
      return response; // Return the response data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message); // Handle errors
    }
  }
);

//Get INDUSTRIES
export const getIndustries = createAsyncThunk(
  "search/getIndustries",
  async (_, { rejectWithValue }) => {
    try {
      const response = await GET("industry"); // Replace with the actual API endpoint for industries
      return response; // Return the industry data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message); // Handle errors
    }
  }
);

// Create an async thunk for triggering the scraper
export const triggerScraper = createAsyncThunk(
  "search/triggerScraper",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await POST("scraper/trigger", formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const initialState = {
  jobTitles: [], // Holds the job titles
  tech_stack: [],
  excludeKeywords: [], // Holds the exclusive keywords
  filters: {
    minEmployees: 1,
    maxEmployees: 10000,
    minRevenue: 100000,
    maxRevenue: 1000000000,
    emailGradeA: true,
    emailGradeB: true,
  },
  industry: [],
  excludeIndustries: "",
  sic: [],
  excludeSic: [],
  industriesList: [], // Holds the fetched industries
  state: "",
  excludeState: "",
  city: "",
  zip: [],
  excludeZip: [],
  excludeCity: "",
  companyName: "",
  excludeCompanyName: "",
  loading: false,
  error: null,
  isScraperRunning: false,
  scraperError: null,
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setJobTitles(state, action) {
      state.jobTitles = action.payload;
    },
    setTechStack(state, action) {
      state.tech_stack = action.payload;
    },
    setExcludeKeywords(state, action) {
      state.excludeKeywords = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    setIndustry(state, action) {
      state.industry = { ...state.industry, ...action.payload };
    },
    setExcludeIndustries(state, action) {
      state.excludeIndustries = action.payload;
    },
    setSelectSic(state, action) {
      state.sic = action.payload;
    },
    setSelectExcludeSic(state, action) {
      state.excludeSic = action.payload;
    },
    setState(state, action) {
      state.state = action.payload;
    },
    setExcludeState(state, action) {
      state.excludeState = action.payload;
    },
    setCity(state, action) {
      state.city = action.payload;
    },
    setExcludeCity(state, action) {
      state.excludeCity = action.payload;
    },
    setZip(state, action) {
      state.zip = action.payload;
    },
    setExcludeZip(state, action) {
      state.excludeZip = action.payload;
    },
    setCompanyName(state, action) {
      state.companyName = action.payload;
    },
    setExcludeCompanyName(state, action) {
      state.excludeCompanyName = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    clearFilters(state) {
      return {
        ...initialState, // Reset to initial state
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateFilters.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateFilters.fulfilled, (state, action) => {
        state.loading = false;
        // Update filters based on API response, spreading the existing state
        state.filters = { ...state.filters, ...action.payload };
      })
      .addCase(updateFilters.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Set the error message
      })
      .addCase(getIndustries.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getIndustries.fulfilled, (state, action) => {
        state.loading = false;
        state.industriesList = action.payload; // Update the industries list with the API response
      })
      .addCase(getIndustries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Set the error message
      })
      .addCase(triggerScraper.pending, (state) => {
        state.isScraperRunning = true;
        state.scraperError = null;
      })
      .addCase(triggerScraper.fulfilled, (state, action) => {
        state.isScraperRunning = false;
        // Update filters with scraper response data if available
        if (action.payload?.data?.length > 0) {
          state.filters = {
            ...state.filters,
            data: action.payload.data,
            count: action.payload.count,
            companyCount: action.payload.company_count,
            pagination: action.payload.pagination,
          };
        }
      })
      .addCase(triggerScraper.rejected, (state, action) => {
        state.isScraperRunning = false;
        state.scraperError = action.payload;
      });
  },
});

// Export the actions and reducer
export const {
  setTechStack,
  setJobTitles,
  setExcludeKeywords,
  setFilters,
  setCompanyName,
  setExcludeCompanyName,
  setIndustry,
  setExcludeIndustries,
  setSelectSic,
  setSelectExcludeSic,
  setState,
  setExcludeState,
  setCity,
  setZip,
  setExcludeZip,
  setExcludeCity,
  clearFilters,
  setLoading,
  setError,
} = searchSlice.actions;
export default searchSlice.reducer;
