import { Link, useParams } from "react-router-dom";

import Table from "../../molecules/Table";
import Icons from "../../../assets/icons";
import { Button } from "../../atoms/buttons";
import { useEffect, useState } from "react";
import { GET } from "../../../services/apiServices";

export default function Discount() {
  const { id: customerId } = useParams();
  const [discounts, setDiscounts] = useState([]);

  useEffect(() => {
    GET(`/customer/discounts/${customerId}`)
      .then((response) => {
        setDiscounts(response.data);
      })
      .catch((error) => {
        console.log("Error fetching discounts", error);
      });
  }, []);
  console.log(
    "discounts",
    discounts.map((discount) => {
      return [];
    })
  );
  const headers = ["Discount code", "Name", "Discount %"];
  const data = discounts.map((discount) => {
    return [
      <div
        className="flex w-[25%] items-center gap-1"
        key={discount?.couponDetails?.id}
      >
        <Icons.codeIcon />
        <div className="text-[#313d31] text-xs font-normal">
          {discount?.couponDetails?.id}
        </div>
      </div>,
      <div className="flex gap-1 items-center">
        <div className="text-[#313d31] text-xs font-normal">
          {discount?.couponDetails?.name}
        </div>
      </div>,
      <div className="flex gap-1 items-center">
        <Icons.discountIcon />
        <div className="text-[#313d31] text-xs font-normal">
          {discount?.couponDetails?.percent_off}%
        </div>
      </div>,
    ];
  });

  return (
    <div className="w-full ">
      <Table headers={headers} data={data} length={false} />
    </div>
  );
}
