import React, { useEffect, useState } from "react";
import { Button, Modal, Select, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getIndustries } from "../../redux/slices/searchSlice";
import { sendRequestData } from "../../redux/slices/requestDataSlice";
import toast from "react-hot-toast";

const { TextArea } = Input;

const RequestDataPopup = ({ open, onClose, isLoading, selectIndustry }) => {
  const dispatch = useDispatch();
  console.log("selectIndustry", selectIndustry);
  const ind = selectIndustry?.map((item) => ({
    label: item,
    value: item,
  }));
  const [formValues, setFormValues] = useState({
    industries: ind || [], // Array to hold selected industries
    description: "",
  });
  const [errors, setErrors] = useState({});
  const industries = useSelector((state) => state.search.industriesList); // Get industries from Redux state
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    dispatch(getIndustries());
  }, [dispatch]);

  const industrieslabel = industries?.data?.map((item) => ({
    label: item?.label,
    value: item?.label,
  }));

  const validate = () => {
    let errors = {};

    if (formValues.industries.length === 0) {
      errors.industries = "Please select at least one industry.";
    }

    if (!formValues.description.trim()) {
      errors.description = "Description cannot be empty.";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    }
  };

  const handleSelectChange = (value, options) => {
    setFormValues({ ...formValues, industries: options || [] });

    // Clear error message for the industries field
    if (errors.industries) {
      setErrors((prevErrors) => ({ ...prevErrors, industries: null }));
    }
  };

  const handleRequestData = async () => {
    if (!validate()) {
      return;
    }

    setIsSubmitting(true);

    const requestData = {
      industries: formValues.industries.map((option) => option.value), // Extract values from selected options
      description: formValues.description,
    };

    try {
      // Dispatch the sendRequestData thunk action
      const response = await dispatch(sendRequestData(requestData));
      if (response.meta.requestStatus === "fulfilled") {
        toast.success("Data request created successfully"); // Show success toast
        onClose(); // Close the popup after success
      }
    } catch (error) {
      toast.error(error?.message || "Failed to submit request."); // Show error toast
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      title="Request Data"
      open={open}
      onCancel={onClose}
      centered
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleRequestData}
          loading={isSubmitting}
          style={{ backgroundColor: "#22c55e" }}
        >
          Request Data
        </Button>,
      ]}
    >
      {/* Industry Select Field */}
      <div className="mb-4">
        <label className="block mb-2">Industry</label>
        <Select
          mode="tags"
          style={{ width: "100%" }}
          placeholder="Select or create industries..."
          value={formValues.industries.map((i) => i.value)}
          onChange={handleSelectChange}
          options={industrieslabel}
          status={errors.industries ? "error" : ""}
        />
        {errors.industries && (
          <p className="text-red-500 text-sm mt-1">{errors.industries}</p>
        )}
      </div>

      {/* Description Field */}
      <div className="mb-4">
        <label className="block mb-2">Description</label>
        <TextArea
          name="description"
          value={formValues.description}
          onChange={handleInputChange}
          placeholder="Enter description"
          rows={4}
          status={errors.description ? "error" : ""}
        />
        {errors.description && (
          <p className="text-red-500 text-sm mt-1">{errors.description}</p>
        )}
      </div>
    </Modal>
  );
};

export default RequestDataPopup;
